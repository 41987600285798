import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <p>
          m8ey
        </p>
        <button onClick={() => console.log(process.env.TEST_SECRET)}>
          test
        </button>
      </header>
    </div>
  );
}

export default App;
